.myzoom:hover {
  transform: scale(1.3);
  padding: 0 10px;
  transition: all 0.25s ease-in-out;
}

.myzoom {
  transition: all 0.25s ease-in-out;
}

.card-zoom:hover {
  transform: scale(1.075);
  transition: all 0.25s ease-in-out;
}

.card-zoom {
  transition: all 0.25s ease-in-out;
}

.BrainhubCarousel__arrows {
  outline: none !important;
}

.BrainhubCarousel__arrows {
  background-color: #212529;
}

.BrainhubCarousel__arrows:hover:enabled,
.BrainhubCarousel__arrows:hover {
  background-color: #4d5257;
}

.BrainhubCarousel__arrows:disabled {
  background-color: #ccc !important;
}